<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <v-chart class="sentiment-pie" :option="sentimentPieOptions" />
          <div class="ml-3">
            <h5 class="font-weight-bold mb-0 text-success">POSITIF <span
                class="text-success font-size-sm font-weight-normal"> (+2.9%)</span></h5>
            <span class="badge badge-mark border-success mr-1"></span> <span class="text-muted">Jun 16, 10:00
              am</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <ul class="nav nav-pills justify-content-end mb-0">
            <li class="nav-item"><a href="#right-pill1" class="nav-link bg-indigo-400 active" data-toggle="tab">Days</a>
            </li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Week</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Month</a></li>
          </ul>
          <div id="tickets-status"></div>
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker
                class="form-control"
                control-container-class=""
                opens="left"
                append-to-body
                :date-range="dateRange"
                style="min-width: 180px"
              />
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <template #header>
          <b-row>
            <b-col md="auto">
              <b-card-title title-tag="h6" class="font-weight-bold">Mention Summary</b-card-title>
            </b-col>
            <b-col md="5" class="mx-auto">
              <b-row>
                <b-col md="5">
                  <b-form-select v-model="selMention" :options="mentionOptions" size="sm" />
                </b-col>
                <b-col md="auto">
                  <p class="font-weight-bold mb-0 pt-1">VS</p>
                </b-col>
                <b-col md="5">
                  <b-form-select v-model="selMetric" :options="metricOptions" size="sm" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <div class="chart-container">
          <v-chart :option="mentionSummaryOptions" class="chart has-fixed-height" />
        </div>
      </b-card>
      <b-row>
        <b-col>
          <b-card header-class="header-elements-sm-inline">
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Mention Percentage</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="mentionPercentageOptions" />
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card header-class="header-elements-sm-inline">
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Mention by Source</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="mentionSourceOptions" />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card header-class="header-elements-sm-inline">
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Mention by Location</b-card-title>
        </template>
        <div class="chart-container">
          <v-chart class="chart has-fixed-height" :option="mentionLocationOptions" />
        </div>
      </b-card>

      <b-card header-class="header-elements-sm-inline">
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">New Mentions</b-card-title>
          <div class="header-elements">
            <a href="#" class="btn bg_default  rounded-round btn-icon btn-sm">
              <i class="icon-spinner11"></i>
            </a>
          </div>
        </template>
      </b-card>
      <div class="card_mention">
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                  <i class="icon-facebook"></i>
                </a>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="card_mention">
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                  <i class="icon-instagram"></i>
                </a>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="card_mention">
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                  <i class="icon-twitter"></i>
                </a>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="card_mention">
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                  <i class="icon-facebook"></i>
                </a>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@bobatimeindonesia</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="card_mention">
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                  <i class="icon-instagram"></i>
                </a>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@indosuryafinance</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="card_mention">
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                  <i class="icon-twitter"></i>
                </a>
              </div>
              <div>
                <a href="#" class="text-default font-weight-bold letter-icon-title">@kemilaumarmer</a>
                <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span> Active
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <p>
                lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan kerja
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>

<script>
import chartDatas from '@/dummies/chartDatas'
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  PieChart,
  LineChart,
  BarChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  TimelineComponent,
} from "echarts/components";
import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

use([
  CanvasRenderer,
  PieChart,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  TimelineComponent,
])

export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return {
      dateRange: {},
      sentimentPieOptions: chartDatas.sentimentPieOptions,
      mentionSummaryOptions: chartDatas.mentionSummaryOptions,
      mentionPercentageOptions: chartDatas.mentionPercentageOptions,
      mentionLocationOptions: chartDatas.mentionLocationOptions,
      mentionSourceOptions: chartDatas.mentionSourceOptions,
      socmedFollowerOptions: chartDatas.socmedFollowerOptions,
      leadsOptions: chartDatas.leadsOptions,
      selMention: '',
      selMetric: '',
      mentionOptions: [
        { text: 'All Mention', value: '' },
        { text: 'Positive Mention', value: 'opt1' },
        { text: 'Social Media', value: 'opt2' },
        { text: 'Non-Social Media', value: 'opt3' },
      ],
      metricOptions: [
        { text: 'All Metric', value: '' },
      ]
    }
  }
}
</script>